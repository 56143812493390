/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  InputAdornment
} from '@mui/material';

// mui icon

//

import { Helmet } from 'react-helmet';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from 'lodash/debounce';
import { useToast } from '@context/toastContext';
import moment from 'moment';
import { getErrorMessage } from '@api/handleApiError';

// modal

// constant
import { TransactionsStatus } from '@utils/constants';
// component
import UILoader from '@components/UILoader';
import { searchTransactionsAPI } from '@api/main';
import { DateRangePicker } from '@mui/lab';
import { format } from 'date-fns';

const rowsPerPageOptions = [10, 20, 50];

const Transactions = () => {
  const showToast = useToast();
  const [transactions, setTransactions] = useState();
  const [totalRows, setTotalRows] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [isSuccess, setIsSuccess] = useState(2);
  const [date, setDate] = useState([moment().startOf('month').valueOf(), new Date().valueOf()]);
  const statusId = isSuccess === 2 ? null : isSuccess;
  const [searchFilter, setSearchFilter] = useState({
    fromDate: date[0],
    toDate: date[1],
    fromAmount,
    toAmount,
    isSuccess: statusId
  });

  const handleSearch = () => {
    if (toAmount < fromAmount) {
      setToAmount(fromAmount * 1 + 300000);
    }
    setSearchFilter({
      fromDate: date[0],
      toDate: date[1],
      fromAmount,
      toAmount,
      isSuccess: statusId
    });
    setPage(0);
  };

  const handlefromAmountChange = (event) => {
    setFromAmount(event.target.value);
    setToAmount(event.target.value * 1 + 300000);
  };
  const handletoAmountChange = (event) => {
    setToAmount(event.target.value);
  };
  const handleStatusChange = (e) => {
    setIsSuccess(e.target.value);
  };
  const handleClear = () => {
    setSearchFilter({
      fromAmount: null,
      toAmount: null,
      fromDate: moment().startOf('month').valueOf(),
      toDate: new Date().valueOf(),
      isSuccess: ''
    });
    setFromAmount('');
    setToAmount('');
    setDate([moment().startOf('month').valueOf(), new Date().valueOf()]);
    setIsSuccess(2);
    setPage(0);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setRowPerPage(event.target.value);
    setRefreshToggle(!refreshToggle);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchData(rowPerPage, page + 1, searchFilter);
  };

  const fetchData = useCallback(
    debounce(async (rowPerPage, page, searchFilter) => {
      try {
        setIsLoading(true);
        const result = await searchTransactionsAPI(rowPerPage, page, searchFilter);
        setTransactions(result.data.pageData);
        setTotalRows(result.data.paging.totalItem);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          type: 'error',
          message: getErrorMessage(error)
        });
      }
    }, 400),
    []
  );
  useEffect(() => {
    fetchData(rowPerPage, page + 1, searchFilter);
  }, [refreshToggle, searchFilter]);

  const handleSearchInputChange = (value) => {
    setDate([moment(value[0]).startOf('day').valueOf(), moment(value[1]).endOf('day').valueOf()]);
  };
  return (
    <>
      <Helmet>
        <title>Transactions | Supper Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <UILoader open={isLoading} />
        <Container maxWidth={false}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  flex: 1
                }}
              >
                Giao Dịch
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={2.5} xs={12}>
                      <DateRangePicker
                        startText="Ngày"
                        endText="Ngày"
                        value={date}
                        onChange={handleSearchInputChange}
                        inputFormat="dd/MM/yyyy"
                        renderInput={({ inputProps, ...startProps }, endProps) => {
                          const startValue = inputProps.value;
                          delete inputProps.value;
                          return (
                            <TextField
                              fullWidth
                              {...startProps}
                              inputProps={inputProps}
                              value={`${startValue} - ${endProps.inputProps.value}`}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={2.5} xs={12}>
                      <TextField
                        onChange={handleStatusChange}
                        value={isSuccess}
                        select
                        fullWidth
                        label="Trạng Thái"
                        variant="outlined"
                      >
                        <MenuItem value={2}>Tất Cả</MenuItem>
                        {TransactionsStatus.map((i, index) => (
                          <MenuItem key={index} value={i.value}>
                            {i.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={2.5} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          type="number"
                          fullWidth
                          label="Khoảng Tiền Từ"
                          variant="outlined"
                          onChange={handlefromAmountChange}
                          value={fromAmount}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">VND</InputAdornment>
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={2.5} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          error={toAmount * 1 < fromAmount * 1}
                          type="number"
                          helperText={toAmount * 1 < fromAmount * 1 ? `Giá trị này phải lớn hơn ${fromAmount}` : false}
                          fullWidth
                          label="Đến"
                          variant="outlined"
                          value={toAmount}
                          onChange={handletoAmountChange}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">VND</InputAdornment>
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          py: 1
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mr: 1, height: '40px', width: '105px' }}
                          onClick={handleSearch}
                        >
                          <Typography variant="h6">Tìm Kiếm</Typography>
                        </Button>
                        <Button sx={{ height: '40px' }} color="primary" variant="outlined" onClick={handleClear}>
                          Xóa
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Mã Hóa Đơn</TableCell>
                        <TableCell>Ngày Tạo</TableCell>
                        <TableCell>Ngày Hoàn Thành</TableCell>
                        <TableCell>Cổng Thanh Toán</TableCell>
                        <TableCell>Phương Thức Thanh Toán</TableCell>
                        <TableCell>Tổng Tiền</TableCell>
                        <TableCell>Trạng Thái</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactions?.map((i) => (
                        <TableRow hover key={i.transactionId}>
                          <TableCell>
                            <Typography variant="body2" sx={{ width: '150px' }} noWrap>
                              {i.orderCode}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" noWrap>
                              {format(i.created_at_utc, 'dd/MM/yyyy')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{format(i.completed_at_utc, 'dd/MM/yyyy')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{i.payment_gateway_id}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{i.payment_method}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(i.amount)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {i.isSuccess ? (
                              <Chip
                                label="Hoàn Thành"
                                color="success"
                                sx={{ fontWeight: 'bold', height: '25px', borderRadius: '6px' }}
                              />
                            ) : (
                              <Chip
                                label="Chưa Hoàn Thành"
                                color="default"
                                sx={{ fontWeight: 'bold', height: '25px', borderRadius: '6px' }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                onPageChange={handlePageChange}
                page={page}
                count={totalRows}
                rowsPerPage={rowPerPage}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Transactions;
