import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { refreshTokenAC } from '@store/actions/auth';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256
  }
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { isUserLoggedIn } = useSelector((state) => state.auth);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(refreshTokenAC());
    }
  }, [isUserLoggedIn]);

  if (!isUserLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar name="" onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        name="Supper Admin"
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
