import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from '@context/toastContext';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// mui
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  Chip,
  Input,
  Button,
  DialogActions,
  Select,
  MenuItem,
  TextField
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { addDays, format } from 'date-fns';

// components
import { getErrorMessage } from '@api/handleApiError';
import FormError from '@components/FormError/FormError';

// constants
import { statusConfig, ClinicPlanTypeEnum, ClinicPlanTypeConfig } from '@utils/constants';

// api
import { getCLinicDetailAPI, updateClinicDetailAPI } from '@api/main';
import { enumToSelectOptions } from '@utils/utils';

const ClinicEditModals = ({ open, clinic, close, isEditable }) => {
  const showToast = useToast();
  const tomorrow = addDays(new Date(), 1);

  const EditClinicSchema = yup.object().shape({
    customerCode: yup.string().required().trim(),
    contractInfo: yup.string().required().trim(),
    address: yup.string().required().trim(),
    staffInCharge: yup.string().required().trim(),
    contactInfo: yup.string().required().trim(),
    specialty: yup.string().max(128).trim(),
    clinicPlanTypeId: yup.number(),
    expiredDateAtUnix: yup.date()
  });

  const defaultValues = {
    clinicId: '',
    clinicName: '',
    contractInfo: '',
    address: '',
    staffInCharge: '',
    customerCode: '',
    specialty: '',
    contactInfo: '',
    clinicPlanTypeId: ClinicPlanTypeEnum.Trial,
    expiredDateAtUnix: addDays(new Date(), 30)
  };
  const { register, handleSubmit, reset, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(EditClinicSchema),
    defaultValues
  });

  useEffect(async () => {
    try {
      if (clinic) {
        const result = await getCLinicDetailAPI(clinic.clinicId);
        reset({
          ...defaultValues,
          ...{
            customerCode: result.data.customerCode,
            clinicId: result.data.clinicId,
            clinicName: result.data.clinicName,
            contractInfo: result.data.contractInfo,
            address: result.data.address,
            staffInCharge: result.data.staffInCharge,
            contactInfo: result.data.contactInfo,
            specialty: result.data.specialty,
            clinicPlanTypeId: result.data.clinicPlanTypeId,
            expiredDateAtUnix: new Date(result.data.expiredDateAtUnix)
          }
        });
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  }, [clinic, reset]);

  const onSubmit = async (data) => {
    try {
      data.expiredDateAtUnix = data.expiredDateAtUnix.getTime();
      await updateClinicDetailAPI(clinic.clinicId, data);
      close('SAVED');
      showToast({
        type: 'success',
        message: 'Cập nhật thông tin phòng khám thành công !'
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    }
  };

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            justifyContent: 'space-between',
            backgroundColor: '#F4F6F8'
          }}
        >
          <DialogTitle sx={{ p: 0 }}>
            <Typography
              noWrap
              sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '25px', fontWeight: 'bold' }}
            >
              {clinic.clinicName}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {clinic.clinicId}
            </Typography>
          </DialogTitle>
          <Chip
            label={statusConfig[clinic.clinicStatusId]?.label}
            color={statusConfig[clinic.clinicStatusId]?.color}
            sx={{ mr: 3, fontWeight: 'bold', borderRadius: '6px' }}
          />
        </Box>
        <DialogContent>
          <Box>
            <Box sx={{ pb: 1, display: 'block' }}>
              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Mã Khách Hàng:</Typography>
                <Box>
                  <Input
                    disabled={!isEditable}
                    error={!!errors.customerCode}
                    sx={{ width: '100%' }}
                    name="customerCode"
                    inputRef={register()}
                  />
                  {errors && errors.customerCode && <FormError>{errors.customerCode.message}</FormError>}
                </Box>
              </Box>
              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Địa Chỉ:</Typography>
                <Box>
                  <Input
                    disabled={!isEditable}
                    error={!!errors.address}
                    fullWidth
                    name="address"
                    inputRef={register()}
                  />
                  {errors && errors.address && <FormError>{errors.address.message}</FormError>}
                </Box>
              </Box>
              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Đầu mối liên hệ:</Typography>
                <Box>
                  <Input
                    disabled={!isEditable}
                    fullWidth
                    error={!!errors.contactInfo}
                    name="contactInfo"
                    inputRef={register()}
                  />
                  {errors && errors.contactInfo && <FormError>{errors.contactInfo.message}</FormError>}
                </Box>
              </Box>
              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Hợp Đồng:</Typography>
                <Box>
                  <Input
                    disabled={!isEditable}
                    error={!!errors.contractInfo}
                    sx={{ width: '100%' }}
                    name="contractInfo"
                    inputRef={register()}
                  />
                  {errors && errors.contractInfo && <FormError>{errors.contractInfo.message}</FormError>}
                </Box>
              </Box>

              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Chuyên Khoa: </Typography>
                <Box>
                  <Input
                    disabled={!isEditable}
                    error={!!errors.specialty}
                    fullWidth
                    name="specialty"
                    inputRef={register()}
                  />
                  {errors && errors.specialty && <FormError>{errors.specialty.message}</FormError>}
                </Box>
              </Box>

              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Nhân Viên Phụ Trách</Typography>
                <Box>
                  <Input
                    disabled={!isEditable}
                    fullWidth
                    error={!!errors.staffInCharge}
                    name="staffInCharge"
                    inputRef={register()}
                  />
                  {errors && errors.staffInCharge && <FormError>{errors.staffInCharge.message}</FormError>}
                </Box>
              </Box>

              <Box sx={{ height: '75px' }}>
                <Typography variant="caption">Loại gói: </Typography>
                <Box>
                  <Controller
                    name="clinicPlanTypeId"
                    control={control}
                    render={({ value, onChange }) => (
                      <Select
                        sx={{ width: '50%' }}
                        disabled={!isEditable}
                        value={value}
                        onChange={onChange}
                        error={!!errors.clinicPlanTypeId}
                      >
                        {enumToSelectOptions(ClinicPlanTypeEnum).map((c) => (
                          <MenuItem key={c.value} value={c.value}>
                            {ClinicPlanTypeConfig[c.value].title}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />

                  {errors && errors.clinicPlanTypeId && <FormError>{errors.clinicPlanTypeId.message}</FormError>}
                </Box>
              </Box>

              <Box sx={{ height: '75px', marginTop: '5px' }}>
                <Typography variant="caption">Thời Gian Hết Hạn: </Typography>
                <Controller
                  name="expiredDateAtUnix"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <DatePicker
                        value={value}
                        disabled={!isEditable}
                        renderInput={(props) => (
                          <Box>
                            <TextField
                              {...props}
                              disabled={!isEditable}
                              value={value ? format(value, 'dd-MM-yyyy') : ''}
                            />
                          </Box>
                        )}
                        onChange={onChange}
                        minDate={tomorrow}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {isEditable ? (
            <Button type="submit" color="success" variant="contained" sx={{ mr: 3, mb: 1 }}>
              Lưu
            </Button>
          ) : (
            <Button onClick={close} color="error" variant="contained" sx={{ mr: 3, mb: 1 }}>
              Đóng
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ClinicEditModals;
